import * as React from "react";
import { ContentWrapper } from "./content-wrapper";
import GuideNarrow from "../svgs/guide-narrow.inline.svg";
import GuideWide from "../svgs/guide-wide.inline.svg";

const HeroImgGuide: React.FC = () => {
  return (
    <ContentWrapper noSidePad noTrans className="md:pt-vgap-xl pb-vgap-xl">
      <GuideNarrow className="block lg:hidden" />
      <GuideWide className="hidden lg:block" />
      <p className="sr-only">
        経営者なら必見のドラッカーの理論を学べるセミナーです。ご自宅からでもオフィスからでも、ご参加いただけます。
      </p>
    </ContentWrapper>
  );
};

export { HeroImgGuide };
