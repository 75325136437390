import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { AddressBlock } from "@topm/shared";
import { AddressImgQuery } from "../graphqlTypes";

interface IWrappedAddressBlockProps {
  className?: string;
}

const WrappedAddressBlock: React.FC<IWrappedAddressBlockProps> = ({
  className,
}) => {
  const data: AddressImgQuery = useStaticQuery(graphql`
    query AddressImg {
      allPrismicSharedMedia {
        edges {
          node {
            data {
              company {
                fluid(maxWidth: 200) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const imgFluid = data.allPrismicSharedMedia.edges[0].node.data.company.fluid;
  return <AddressBlock className={className} imgFluid={imgFluid} />;
};

export { WrappedAddressBlock as AddressBlock };
