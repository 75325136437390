import * as React from "react";
import { ContentWrapper } from "../components/content-wrapper";

interface IHeroImgProps {
  srcHeroNarrow: string;
  srcHeroWide: string;
  alt?: string;
}
const HeroImg: React.FC<IHeroImgProps> = ({
  srcHeroNarrow,
  srcHeroWide,
  alt = "",
}) => (
  <ContentWrapper noShadow noSidePad className="pt-vgap-lg lg:mt-[24px]">
    <h1>
      <span className="block -mx-15px lg:hidden">
        <img className="block w-full" src={srcHeroNarrow} alt={alt} />
      </span>
      <span className="hidden lg:block">
        <img className="block w-full" src={srcHeroWide} alt={alt} />
      </span>
    </h1>
  </ContentWrapper>
);

export { HeroImg };
