import * as React from "react";
import JsxParser from "react-jsx-parser";

import { ProgramDetail } from "@topm/shared";
import { TextList } from "@topm/shared";
import { FadeIn } from "@topm/shared";
import { RegistrationNav } from "./registration-nav";
import { AddressBlock } from "./address-block";

interface IJsxFormatter {
  jsx: string;
  className?: string;
}
const JsxFormatter: React.FC<IJsxFormatter> = ({ jsx, className }) => {
  const components = {
    ProgramDetail,
    TextList,
    AddressBlock,
    RegistrationNav,
    FadeIn,
  };
  return (
    <div className={className}>
      <JsxParser components={components} jsx={jsx} />
    </div>
  );
};

export { JsxFormatter };
