import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ProfileBlock } from "@topm/shared";
import { ProfileImgQuery } from "../graphqlTypes";

const WrapperProfileBlock: React.FC<any> = (props) => {
  const data: ProfileImgQuery = useStaticQuery(graphql`
    query ProfileImg {
      allPrismicSharedMedia {
        edges {
          node {
            data {
              books {
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
              profile {
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const root = data.allPrismicSharedMedia.edges[0].node.data;

  return (
    <ProfileBlock
      imgFluidProfile={root.profile.fluid}
      imgFluidBooks={root.books.fluid}
      {...props}
    />
  );
};

export { WrapperProfileBlock as ProfileBlock };
