import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { RegistrationNav } from "@topm/shared";
import { RegistrationUrlQuery } from "../graphqlTypes";

const WrappedRegistrationNav: React.FC = () => {
  const data: RegistrationUrlQuery = useStaticQuery(graphql`
    query RegistrationUrl {
      allPrismicLpTopmFive {
        edges {
          node {
            data {
              registration_url
            }
          }
        }
      }
    }
  `);

  const href = data.allPrismicLpTopmFive.edges[0].node.data.registration_url;
  return <RegistrationNav href={href} />;
};

export { WrappedRegistrationNav as RegistrationNav };
